<template>
  <div class="product-deteils">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/vendor/products">Products</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.title_en }}</span>
    </div>
    <v-card outlined class="mt-4">
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">Product details</div>
        <div class="text--text mb-3">Provide general information about the product</div>
        <div class="my-5 d-flex align-center flex-wrap">
          <div class="font-weight-bold">Status</div>
          <v-btn-toggle class="group-btn ms-5" v-model="data.status" @change="updateStatus">
            <v-btn class="text-body-2" value="listed" outlined height="36">Listed</v-btn>
            <v-btn class="text-body-2" value="unlisted" outlined height="36">Unlisted</v-btn>
          </v-btn-toggle>
        </div>
        <div class="font-weight-bold mb-3">General Info</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-textarea
            class="field46 width100 me-md-3"
            v-model="data.title_en"
            placeholder="Title"
            :error-messages="titleEnErrors"
            auto-grow
            rows="1"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.title_ar"
            placeholder="عنوان"
            :error-messages="titleArErrors"
            auto-grow
            rows="1"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-textarea
            class="width100 me-md-3"
            v-model="data.description_en"
            placeholder="Description"
            :error-messages="desEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.description_ar"
            placeholder="وصف كامل"
            :error-messages="desArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div class="text--text mb-3 mt-n2">Price</div>
        <v-text-field
          class="field46 mt-n2 price-field"
          v-model="data.priceR"
          placeholder="0"
          suffix="SAR"
          type="number"
          :error-messages="priceErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="text-h6 font-weight-bold">Photos</div>
        <div class="text--text mb-4">Please, drag the main photo ont the first place</div>
        <v-card
          v-if="data.media"
          flat
          class="primary lighten-3 d-flex align-center justify-center"
          height="440"
          rounded="lg"
          style="position: relative"
        >
          <div v-if="!data.media.length">
            <v-img class="mb-3" contain height="48" src="@/assets/icon/img.svg"></v-img>
            <v-btn @click="addPhoto" depressed width="116" height="30" class="primary rounded text-body-2">Choose media</v-btn>
          </div>
          <div v-else class="d-flex align-center justify-center">
            <v-img class="rounded-lg" contain :src="data.media[pos].url" height="440"></v-img>
          </div>
          <div class="galery d-flex align-center pa-3" v-if="data.media.length">
            <div class="d-flex align-center width100" style="max-width: 610px">
              <v-slide-group next-icon="mdi-chevron-right-circle" prev-icon="mdi-chevron-left-circle">
                <v-slide-item v-for="(j, i) in data.media" :key="j.id">
                  <v-img
                    @click="pickImg(i)"
                    class="rounded-lg link d-flex align-center text-center"
                    cover
                    :src="j.url"
                    height="92"
                    max-width="92"
                  >
                    <div @click.stop="removeImg(j.id, i)" v-if="pos == i" class="close-btn">
                      <v-icon color="white">mdi-close</v-icon>
                    </div>
                  </v-img>
                </v-slide-item>
              </v-slide-group>
            </div>
            <v-spacer></v-spacer>
            <v-btn @click="addPhoto" class="add-btn ms-3" icon large>
              <img src="@/assets/icon/btn.svg" alt="plus" />
            </v-btn>
          </div>
        </v-card>
      </div>
      <v-divider class="mt-2"></v-divider>
      <div class="d-flex align-center py-3 px-sm-10 px-3">
        <v-btn @click="modal = true" class="rounded" outlined depressed height="30" width="116">
          <span class="error--text">Remove</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="updateItem" depressed class="rounded primary" height="30" width="106">Save</v-btn>
      </div>
    </v-card>
    <v-dialog v-model="modal" width="480">
      <v-card class="pa-4">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to remove vendor product {{ data.title_en }}?</div>
        <div class="text-end">
          <v-btn @click="modal = false" outlined class="rounded" color="gray lighten-1" width="106" height="30">
            <span class="text-body-2 black--text">Cancel</span>
          </v-btn>
          <v-btn @click="removeProduct" depressed class="error rounded ms-3" width="106" height="30">
            <span class="text-body-2">Remove</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      pos: 0,
      error: [],
    };
  },
  created() {
    this.$store.dispatch('getVendorProductItem', this.$route.params.vendorId);
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor item saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor item updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'removeImg') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor media item removed successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor item removed successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async updateStatus() {
      await this.$store
        .dispatch('statusVendorProductItem', { id: this.$route.params.vendorId, data: { status: this.data.status } })
        .then(() => {
          this.notifi('update');
        });
    },
    addPhoto() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.addMediaItem(this.files[0]);
          var reader = new FileReader();
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    pickImg(i) {
      this.pos = i;
    },
    async updateItem() {
      this.error = [];
      const data = new Object();
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      data.price = this.data.priceR * 100;
      await this.$store
        .dispatch('updateVendorProductItem', { id: this.$route.params.vendorId, data: data })
        .then(() => {
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async addMediaItem(f) {
      this.error = [];
      const formData = new FormData();
      formData.append('type', 'picture');
      formData.append('media', f);
      await this.$store
        .dispatch('setMediaVendorProduct', { id: this.$route.params.vendorId, data: formData })
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async removeImg(id, i) {
      this.error = [];
      await this.$store
        .dispatch('deleteMediaVendorProduct', { id: this.$route.params.vendorId, media: id })
        .then(() => {
          this.pos ? this.pos-- : '';
          this.data.media.splice(i, 1);
          this.notifi('removeImg');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async removeProduct() {
      await this.$store.dispatch('deleteVendorProductItem', this.$route.params.vendorId).then(() => {
        this.$router.push('/vendor/products');
        this.notifi('remove');
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.vendorProduct;
    },
    profile() {
      return this.$store.getters.profile;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setVendorProductItem', {});
  },
};
</script>

<style lang="scss">
#app .product-deteils {
  .v-input {
    max-width: 371px;
  }
  .price-field {
    .v-input__slot {
      max-width: 156px !important;
    }
  }
  .galery {
    position: absolute;
    width: 100%;
    max-width: 706px;
    top: 70%;
    margin: 0 30px;
    background: rgba(15, 15, 15, 0.58);
    border-radius: 8px;
    backdrop-filter: blur(9px);
    .v-image:not(:last-of-type) {
      margin-right: 10px;
    }
    .add-btn {
      // position: absolute;
      border-radius: 50% !important;
      // right: 12px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .close-btn {
      cursor: pointer;
      position: absolute;
      left: 75%;
      top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.6);
      i {
        font-size: 12px !important;
      }
    }
    .v-slide-group {
      .v-slide-group__prev,
      .v-slide-group__next {
        min-width: 0;
        max-width: 0;
        z-index: 2;
        i {
          color: white !important;
        }
      }
      .mdi-chevron-right-circle {
        right: 20px;
      }
      .mdi-chevron-left-circle {
        left: 20px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .v-input {
      max-width: initial !important;
    }
    .group-btn {
      display: grid;
      button {
        border-width: thin !important;
        border-radius: 4px !important;
      }
    }
  }
}
</style>
